import React from "react"
import styled from "@emotion/styled"
import { ComponentContainer, AdminButton , RemoveButton} from "../admin_common"
import Editable2 from "./editable2"

const AddButton = AdminButton.withComponent('button')


const Headerstyle = { 
    fontSize:'1.5em',
    width: '90%',
    marginBottom:'0'

}

const NewsletterHighlightEditable = (props) =>{

    var arg1 = ''
    var arg2 = ''

    if(props.arg1 != null){
        arg1 = props.arg1
    }else{
        arg1 = 'Highlight Title malmsa'//Newsletter Title
    }
    if(props.arg2 != null){
        arg2 = props.arg2
    }else{
        arg2 = 'This is a paragraph lamlas'
    }

    // const [title, setTitle] = useState(arg1);
    // const [paragraph, setParagraph] = useState(arg2);


    

    return(
        <ComponentContainer>
            <RemoveButton  name={props.index} onClick={props.removeFn} style={{display:'inline-block'}}>-</RemoveButton>
            <Editable2
                text={arg1}
                placeholder="Write a title"
                type="input"
                header={'true'}
                basestyle={Headerstyle}
            >
                <input
                    autoFocus={true}
                    type="text"
                    placeholder="Write a title"
                    value={arg1}
                    onChange={props.textChange}
                    style={Headerstyle}
                    name={props.index+'a'}
                />

            </Editable2>
            <Editable2
                text={arg2}
                placeholder="Write paragraph content"
                type="input"
                header={'false'}
            >
                <input
                    autoFocus={true}
                    type="text"
                    name={props.index+'b'}
                    placeholder="Write paragraph content"
                    value={arg2}
                    style={{width: '90%'}}
                    onChange={props.textChange}                    
                />

            </Editable2>
        </ComponentContainer>
    )

}

export default NewsletterHighlightEditable