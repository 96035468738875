import React from "react"
import styled from "@emotion/styled"
import DropSettings from '../menu_settings'
import { getUser } from "../../services/auth"
import Editable from "./editable"
// import { css } from "@emotion/core"

// import { RemoveButton } from "../admin_common"





// const AddButton = AdminButton.withComponent('button')

const FrameContainer = styled.div`
    border: 2px dashed #66adff;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
    position: relative;

    span{
        color: #666;
    }
`

let image = ''

const NewsletterPictureEditable = (props) =>{
    // const [image, setImage] = useState(null);
    let propObj = {
        'index': props.index,
        'arg1': props.arg1,
        'arg2': props.arg2,
        'arg3': props.arg3,

    }

    let dropdown_options = ['change size', 'load image']

    const changeImgSettings = (size) =>{
        if(size == 0){
            propObj['arg3'] = 'panoramic'
        }else if(size == 1){
            propObj['arg3'] = 'middle'
        }else if(size == 2){
            propObj['arg3'] = 'intext'
        }else if(size == 3){
            propObj['arg3'] = 'portrait'
        }

        if(size > 3){
            // console.log('changing img_id: ', size)
            propObj['arg2'] = size
        }
        props.imgUpdate(propObj)
    }

    const onCaptionChange = (text) =>{
        propObj['arg1'] = text
        props.imgUpdate(propObj)
    }


    let imgWidth = '100%'
    let imgHeight = '56vw'
    let imgMargin = 'auto'

    if(props.srcImg && image != props.srcImg){
        // console.log('Image Changed')
        // setImage(props.srcImg)
        image = props.srcImg
    }

    if(props.arg3 == 'panoramic'){
        imgWidth = '100%'
        imgMargin = 'auto'
        imgHeight = '36vw'
    }else if(props.arg3 == 'middle'){
        imgWidth = '80%'
        imgMargin = 'auto'
        imgHeight = '45vw'
    }else if(props.arg3 == 'intext'){
        imgMargin = '0'
        imgWidth = '50%'
        imgHeight = '28vw'
    }else if(props.arg3 == 'portrait'){
        imgMargin = '0'
        imgWidth = '50%'
        imgHeight = '70vw'
    }

    const onImageChange = (event) => {
        let imgName = ''
        if (event.target.files && event.target.files[0]) {
        //   console.log('file: ', event.target.files[0].name)
          imgName = event.target.files[0].name;
          let reader = new FileReader();
          reader.onload = async(e) => {
            // this.setState({image: e.target.result});
            // console.log('Img data: ', e.target.result.split(',')[1])
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/save_img`,
                {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"email": getUser().email, "password": getUser().hash, "img_name": imgName, "img": e.target.result})})
                .then(res => res.json())
                .then(json => {
                    // console.log('json: ', json)
                    propObj['arg2'] = json.id;
                    props.imgUpdate(propObj)
                    // alert('Newsletter succesfully saved')
                    // setNewsletterId({'id': json.id})
                });
            // setImage(e.target.result)
            image = e.target.result
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }


    return(
        <FrameContainer style={{width:imgWidth, margin: imgMargin}}>
            <div style={{position:'absolute', zIndex:'2', top:'0', left:'0'}}>
                {/* <RemoveButton  name={props.index} onClick={props.removeFn} style={{display:'inline-block'}}>-</RemoveButton> */}
                <DropSettings thumbnails={props.thumbnails} index={props.index} arguments={propObj} removeFn={props.removeFn} title="" onImageChange={onImageChange} optionsArr={dropdown_options} optionSelect={changeImgSettings} selected={''}/>
            </div>
            <div style={{width:'100%', position: 'relative', height: imgHeight, overflow: 'hidden'}}>
                <img style={{position:'absolute'}} id="target" src={image}/>
            </div>
            <div style={{paddingLeft:'10px'}}>
                <Editable
                    text={propObj['arg1']}
                    placeholder={propObj['arg1']}
                    type="input"
                    header={'false'}
                    labelstyle={{'color':'#888', 'fontSize':'1.4rem'}}
                >
                    <input
                        autoFocus={true}
                        type="text"
                        name={props.index+'b'}                    
                        placeholder={propObj['arg1']}
                        value={propObj['arg1']}
                        // onChange={e => setDate(e.target.value)}
                        onChange={(evt)=>onCaptionChange(evt.target.value)}
                    />
                </Editable>
                {/* <span>{props.arg1}&nbsp;Image Id: &nbsp;{props.arg2}</span> */}
            </div>
        </FrameContainer>
    )

}

export default NewsletterPictureEditable