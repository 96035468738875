export const isBrowser = () => typeof window !== "undefined"

export const getNewsletterId = () =>
  isBrowser() && window.localStorage.getItem("gatsbyNewsletterID")
    ? JSON.parse(window.localStorage.getItem("gatsbyNewsletterID"))
    : {}

export const setNewsletterId = newsletterArgs =>
    window.localStorage.setItem("gatsbyNewsletterID", JSON.stringify(newsletterArgs))

  