import React, {useState, useEffect} from "react"
// import { navigate } from "gatsby"
import { getUser } from "../services/auth"
// import styled from "@emotion/styled"
import AdminNav from "../admin_components/nav-bar"
import { Bground, TableContainer, EmailTable, AdminButton } from "../admin_components/admin_common"

const AddButton = AdminButton.withComponent('button')

const EmailList = () =>{
    const [list, setList] = useState([{'email': '', 'id':'', 'date_added': 'T'}]);
    
    
    
    const copyList = async(evt) =>{
        // evt.persist()
        var email = ''
        var emails = []
        for (let i = 0; i < list.length; i++) {
            emails.push(list[i].email)
        }
        email = emails.toString()
        await navigator.clipboard.writeText(email)
        window.alert('Email list copied to the clipboard!')

    }

    const sendEmail = async(evt) =>{
        // evt.persist()
        await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/send_email`,
        {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'email': getUser().email, 'password': getUser().hash})})
        .then(res => res.json())
        .then(json => {
            console.log("this is my response: ",json)
            window.alert(json.message)
        });

    }

    useEffect(() => {
        const fetchEmailList = async () => {
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/email_list?email=${getUser().email}&password=${getUser().hash}`,
            {method: "GET", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client"})
            .then(res => res.json())
            .then(json => {
                // console.log('response: ', json)
                setList(json)
                // console.log('list: ', list)
            });
        }
        fetchEmailList()
    }, [])

    return(
        <Bground>
            <AdminNav/>
            <br></br>
            <h2 style={{textAlign:'center'}}>There are {list.length} emails subscribed in total</h2>
            <TableContainer>
                <EmailTable>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Email</th>
                            <th>Date Added</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item,i) =>(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.email}</td>
                                <td>{item.date_added.split('T')[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </EmailTable>  
            </TableContainer> 
            <AddButton onClick={copyList}>Copy List</AddButton>
            <AddButton onClick={sendEmail}>send test email</AddButton>
            <br></br>
            {/* <AddButton onClick={sendEmail}>Send Email</AddButton> */}
        </Bground>
    )

}

export default EmailList