import React from "react"
import { Router } from "@reach/router"
import Login from "../private/admin"
import PrivateRoute from "../components/privateRoute"
import CMS from "../private/cms"
import EmailList from "../private/email_list"
import NewsletterCMS from "../private/newsletter"
import NewsletterEdit from "../private/newsletter_edit"
import ChPassword from "../private/ch_password"


const App = () => (
    <div>
        <Router>
            <PrivateRoute path="/app" component={Login} />
            <PrivateRoute path="/app/email_list" component={EmailList} />
            <PrivateRoute path="/app/newsletter" component={NewsletterCMS} />
            <PrivateRoute path="/app/newsletter_edit" component={NewsletterEdit} />
            <PrivateRoute path="/app/ch_password" component={ChPassword} />
            <PrivateRoute path="/app/cms" component={CMS} />
            <Login path="/app/admin" />
        </Router>
    </div>
  
)

export default App