import React, { useState } from "react";
import styled from "@emotion/styled"
import { css } from "@emotion/core"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { AiFillSetting } from 'react-icons/ai';





const DropdownMenu = styled.div`
  background-color: transparent;
  `
  const Menubtn = styled.button`
    border: none;
    outline: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    
  `
  const DdContent = styled.div`   
    position: absolute;
    background-color: #A6BBEF;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    overflow: auto;
    width: max-content;

    div {
        float: none;
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        cursor: pointer;
      }
    div:hover{
        background-color: #7d9be8;
    }
      ${p => p.open ? css`
        display: block;
  ` : css`
        display: none; 
  `}

   `

const SubSelector = styled.div`   
   position: absolute;
   background-color: #A6BBEF;
   color: white;
   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   z-index: 2;
   overflow: auto;

   div {
       float: none;
       color: white;
       padding: 12px 16px;
       text-decoration: none;
       display: block;
       text-align: left;
       cursor: pointer;
     }
   div:hover{
       background-color: #7d9be8;
   }
     ${p => p.open&&p.open2 ? css`
       display: block;
 ` : css`
       display: none; 
 `}

  `

const Cancelbtn = styled.button`
    border: none;
    outline: none;
    color: white;
    padding: 12px 16px;
    background-color: #ff9999;
    font-family: inherit; 
    margin: 0; 
    width: 100%;
    cursor: pointer;
    text-align: left;

    :hover{
        background-color: #ff3333;
    }
`

const Neutralbtn = styled.button`
    border: none;
    outline: none;
    color: white;
    padding: 12px 16px;
    background-color: #A6BBEF;
    font-family: inherit; 
    margin: 0; 
    width: 100%;
    cursor: pointer;
    text-align: left;

    :hover{
        background-color: #7d9be8;
    }
`

 
const DropSettings = (props) => {
    
    const [isFolded, setIsFolded] = useState(true);
    const [sizeMenu, setSizeMenu] = useState(false);
    const [dbMenu, setDbMenu] = useState(false);

    let selected = ['', '', '', '']

    if(props.arguments['arg3'] == 'panoramic'){
        selected = ['', '', '', '']
        selected[0] ='*'
    }else if(props.arguments['arg3'] == 'middle'){
        selected = ['', '', '', '']
        selected[1] ='*'
    }else if(props.arguments['arg3'] == 'intext'){
        selected = ['', '', '', '']
        selected[2] ='*'
    }else if(props.arguments['arg3'] == 'portrait'){
        selected = ['', '', '', '']
        selected[3] ='*'
    }

    
    const toggleState = (evt) => {
        if(evt != undefined){
            evt.preventDefault()
        }
        // this.setState(prevState => ({
        //     IsFolded: !prevState.IsFolded,
        //   }))
        setIsFolded(!isFolded)
    }

    const select = (evt) => {
        let category = ""
        evt.persist()
        evt.preventDefault()
        console.log(evt._targetInst.pendingProps.name)
        category = evt._targetInst.pendingProps.name
        props.optionSelect(category)
        toggleState()
    }
    
    const onImageChange = (evt)=>{
        props.onImageChange(evt)
        toggleState()
    }
// onBlur={()=>setIsFolded(true)}
    // console.log('sizeMenu: ', sizeMenu)
      return (
        <DropdownMenu>
            <Menubtn onClick={toggleState}>
                {/* {props.title}&nbsp;{props.selected} <FontAwesomeIcon icon={faCaretDown}/> */}
                <AiFillSetting size={36} style={{color:'#214c9b', cursor:'pointer', float:'right', margin:'10px'}}/>
            </Menubtn>
            <DdContent open={!isFolded}>
                <input name={0} type="file" onChange={onImageChange} className="filetype" id="group_image"/>
                <div name={1} onClick={()=>setDbMenu(!dbMenu)}>Choose from data base</div>
                <div name={2} onClick={()=>setSizeMenu(!sizeMenu)}>Change image size</div>
                <Cancelbtn name={props.index} onClick={props.removeFn}>Delete component</Cancelbtn>

                {/* {props.optionsArr.map((element,index) => (
                    <div key={index} name={element} onClick={select}>{element}</div>
                ))} */}
            </DdContent>
            <SubSelector style={{right: '-470%', top:'225%'}} open={sizeMenu} open2={!isFolded}>
                <div onClick={()=>{setSizeMenu(!sizeMenu);props.optionSelect(0)}}>{selected[0]}&nbsp;Panoramic</div>
                <div onClick={()=>{setSizeMenu(!sizeMenu);props.optionSelect(1)}}>{selected[1]}&nbsp;Middle</div>
                <div onClick={()=>{setSizeMenu(!sizeMenu);props.optionSelect(2)}}>{selected[2]}&nbsp;Intext</div>
                <div onClick={()=>{setSizeMenu(!sizeMenu);props.optionSelect(3)}}>{selected[3]}&nbsp;Portrait</div>
            </SubSelector>
            <SubSelector style={{right: '-500px', top:'150%', maxHeight:'300px', maxWidth:'300px', overflow:'auto'}} open={dbMenu} open2={!isFolded}>
                {/* props.thumbnails */}
                {props.thumbnails.map((element,index) => (
                    <Neutralbtn style={{padding: '0', width:'max-content', margin:'10px'}} onClick={()=>{setDbMenu(!dbMenu);props.optionSelect(element.id)}} key={index} name={element.id}><img src={element.format +','+ element.encode}/></Neutralbtn>
                ))}
            </SubSelector>
        </DropdownMenu>
      );
    }
  
  
  export default DropSettings;

