import React, { useState } from "react";
import styled from "@emotion/styled"


const Label = styled.p`
  padding: 0.3rem;
  padding-left: 0.1rem;
  :hover{
    border: 2px solid #66adff;
    background-color: white;
    border-radius: 3px;
  }
`

const Header = styled.h2`
  padding: 0.3rem;
  padding-left: 0.1rem;
  :hover{
    border: 2px solid #66adff;
    background-color: white;
    border-radius: 3px;
  }
`

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable2 = ({
  text,
  type,
  placeholder,
  children,
  ...props
}) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
// Exercise: It can be made dynamic by accepting initial state as props outside the component 
  const [isEditing, setEditing] = useState(false);

// Event handler while pressing any key while editing
  const handleKeyDown = (event, type) => {
    // Handle when key is pressed
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey]; // All keys array

  /* 
    - For textarea, check only Escape and Tab key and set the state to false
    - For everything else, all three keys will set the state to false
  */
    if (
      (type === "textarea" && keys.indexOf(key) > -1) ||
      (type !== "textarea" && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false);
    }
  };

/*
- It will display a label is `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={() => setEditing(false)}
          onKeyDown={e => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div
          onClick={() => setEditing(true)}
        >{props.header=='true' ? (
            <Header style={props.basestyle}>
              {/* {text || placeholder || "Editable content"} */}
              {text || ""}
            </Header>
          ) : (
            <Label style={{width: '90%'}}>
              {/* {text || placeholder || "Editable content"} */}
              {text || ""}
            </Label>
          )}
        </div>
      )}
    </section>
  );
};

export default Editable2;