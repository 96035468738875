import React from "react"
// import styled from "@emotion/styled"
import { ComponentContainer, AdminButton } from "../admin_common"
import Editable from "./editable"

// const AddButton = AdminButton.withComponent('button')


const Headerstyle = { 
    fontSize:'2em',
    width: '90%',
    marginBottom:'0'

}

const NewsletterTittleEditable = (props) =>{
    const today = new Date();//timezone adjustment
    const anio = today.getFullYear();
    const mes = today.getMonth() + 1;
    const dia = today.getDate();
    
    var arg1 = ''
    var arg2 = ''

    if(props.arg1 != null){
        arg1 = props.arg1
    }else{
        arg1 = 'Main title'//Newsletter Title
    }
    if(props.arg2 != null){
        arg2 = props.arg2
    }else{
        arg2 = mes+'/'+dia+'/'+anio
    }

    // const [title, setTitle] = useState(arg1);
    // const [date, setDate] = useState(arg2);



    return(
        <ComponentContainer>
            <Editable
                text={arg1}
                placeholder="Write a title"
                type="input"
                header={'true'}
                basestyle={Headerstyle}
            >
                <input
                    autoFocus={true}
                    type="text"
                    name={props.index+'a'}                    
                    placeholder="Write a title"
                    value={arg1}
                    // onChange={e => setTitle(e.target.value)}
                    onChange={props.textChange}
                    style={Headerstyle}
                />

            </Editable>
            <Editable
                text={arg2}
                placeholder="Write a date"
                type="input"
                header={'false'}
            >
                <input
                    autoFocus={true}
                    type="text"
                    name={props.index+'b'}                    
                    placeholder="Write a date"
                    value={arg2}
                    // onChange={e => setDate(e.target.value)}
                    onChange={props.textChange}
                />

            </Editable>
        </ComponentContainer>
    )

}

export default NewsletterTittleEditable