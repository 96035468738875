import React, {Component} from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import styled from "@emotion/styled"
// import { Link } from "gatsby"

const Bground = styled.div`
    background-image: linear-gradient(#FDE3E3, #BDD3D5);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    height: 100%;
    min-height: 1080px;
    font-size: 16px;
`
const Info = styled.div`
    background-color: #7183AA;
    overflow: auto;
    width: max-content;
    padding: 20px;
    margin: auto;
    color: white;
    position: absolute;

    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    b {
      color: #b3f1f3;
    }

    td {
      padding: 10px;
      vertical-align: middle;
      border-color: #8592ad;
    }

    button {
      background-color: #FCD59D;
      color: white;
      padding: 5px;
      border: none;
      cursor: pointer;
      width: max-content;
      margin: auto;
      display: block;
    }

    h1 {
      color: white;
      margin: auto;
      text-align: center;
      padding: 10px;
      font-size: 24px;
    }
    
`


class Login extends Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async event => {
    await event.preventDefault()
    await handleLogin(this.state)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/app/cms`)
    }

    return (
      <Bground>
        <br></br>
        <Info>
          <h1>Administration Portal</h1>
          <br></br>
          <form
            style={{margin:'auto', width:'max-content'}}
            method="post"
            onSubmit={event => {
              (async () => { 
                await this.handleSubmit(event)
                //console.log("navigate to profile ", isLoggedIn())
              
                navigate(`/app/cms`)
              }) ();
            
            }}
          >
            <label htmlFor="Adminusername">Email:&nbsp;</label>
            <br></br>
            <input type="text" name="username" id="Adminusername" onChange={this.handleUpdate}/>
            <br></br>
            <br></br>
            <label htmlFor="Adminpass">Password:&nbsp; </label>
            <br></br>
            <input
              type="password"
              name="password"
              id="Adminpass"
              onChange={this.handleUpdate}
            />
            <br></br>
            <br></br>
            <input type="submit" value="Log In" style={{backgroundColor: '#FCD59D', color: 'white', padding: '5px', border: 'none', cursor: 'pointer', width: 'max-content', display: 'block'}}/>
            <br></br>            
          </form>
          </Info>
          <br></br>
      </Bground>
    )
  }
}

export default Login