import React, {useState} from "react"
import { logout } from "../services/auth"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'


const TopContainer = styled.div`
  padding: 0.2rem;
  height: 60px;
  background-color: #7183AA;
  width:100%;
  box-shadow: 0px 10px 15px rgba(150, 150, 150, 0.5);
  line-height: 160%;
  z-index: 3;
  
  transition: 0.5s;

  p{
    color: #333;
  }
  font-size: 16px;
    h1 {
        font-size: 24px;
    }
`

const MenuItem = styled.li`
    display: inline;
    margin-right: 1rem;
    padding: 0.1rem;

    
`
// @media (max-width: 995px) {
//     display: none;
//     }

const Menu = styled.ul`
    list-style: none;
    float: right;
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 0;
    right:0;
    padding-top: 1px;
    transition: 0.1s;
    font-size: 15px;

    a{
      text-decoration: none;
      color: #333;
      box-shadow: none;

     
    }
  
`

// @media (max-width: 995px) {
//     display: none;
//     }

const MenuItemResp = styled.li`
    display: block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;

    display: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
`

// @media (min-width: 996px) {
//     display: none;
//   }

const ListLink = props => (
    <MenuItem>
      <Link to={props.to} style={{textDecoration:'none', color:'white', boxShadow:'none', paddingLeft:'0.1rem'}} activeStyle={{border: '1px solid white', borderTopStyle: 'none', borderRightStyle: 'none', borderLeftStyle: 'none', borderBottomStyle: 'solid'}}>{props.children}</Link>
    </MenuItem>
  )



const AdminNav = () =>{
    const [isMobileNavFolded, setIsMobileNavFolded] = useState(true);

    const onRouteChange = () => {
        setIsMobileNavFolded(true)
    }

    const toggleMobileNav = () => {
        if(isMobileNavFolded){
            setIsMobileNavFolded(false)
        }else{
            setIsMobileNavFolded(true)
        }
    }

    const finishSession = () =>{
        logout(() => navigate(`/`))
    }

    return(
        <TopContainer>
            <Link style={{textDecoration:'none', marginTop:'10px', marginLeft:'1.5rem', boxShadow:'none', color:'white', fontSize:'26px', display:'inline-block'}} to="/app/cms">Admin Panel</Link>
            <Menu>
                <ListLink to="/app/newsletter">Newsletter</ListLink>
                <ListLink to="/app/email_list">Email List</ListLink>
                <MenuItem style={{color:'white', cursor:'pointer'}} onClick={finishSession} to="/">Log out</MenuItem>
                <MenuItemResp onClick={toggleMobileNav}><FontAwesomeIcon icon={faBars}/></MenuItemResp>
            </Menu>

        </TopContainer>
    )
}

export default AdminNav