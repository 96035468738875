import React from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { getUser, setUser } from "../services/auth"
import { Bground, AdminButton } from "../admin_components/admin_common"
import AdminNav from "../admin_components/nav-bar"


var bcrypt = require('bcryptjs');
const salt = '$2a$10$xRjs/hBHhfVisrjTql4xuO'


// const Bground = styled.div`
//     background-image: linear-gradient(#FDE3E3, #BDD3D5);
//     background-repeat: no-repeat;
//     background-size: cover;
//     overflow: auto;
// `

const Aquamarine = styled.div`
    background-color: #b3f1f3;
    border-radius: 5px;
    width: 95%;
    max-width: 700px;

    padding:2px;
    font-weight: bold;
    color: #7183AA;
    font-size: 18px;
    text-align: center;
    margin:auto;
`

const Info = styled.div`
    background-color: #7183AA;
    overflow: auto;
    width: max-content;
    padding: 20px;
    margin: auto;
    color: white;

    b {
      color: #b3f1f3;
    }

    td {
      padding: 10px;
      vertical-align: middle;
      border-color: #8592ad;
    }

    button {
      background-color: #FCD59D;
      color: white;
      padding: 5px;
      border: none;
      cursor: pointer;
      width: max-content;
      margin: auto;
      display: block;
      
    }

`
let passwordChanged = false;
let passwordObj = {};

const ChPassword = () => {
    
    const changePassword = async() => { 
        var user = {}
        const hashedUser = bcrypt.hashSync(passwordObj['passwordNew'], salt)
        // /api/v1/change_password
        // https://feodorofflaw-api.herokuapp.com/api/v1/login
        // console.log('new password: ', passwordObj['passwordNew'])
        // console.log('new password hashed: ', hashedUser)
        await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/change_password`,
        {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"email": getUser().email, "password": getUser().hash, "newPassword": hashedUser})})
        .then(res => res.json())
        .then(json => {
            //console.log("this is my response: ", json);
            if (json != 'failed') {
                user = {
                    email: getUser().email,
                    hash: hashedUser,
                  }
                setUser({})
                setUser(user)
                passwordChanged = true;
            }
        })
    }

    const handleSubmit = async event => {
        await event.preventDefault()
        const correct = bcrypt.compareSync(passwordObj['passwordOld'], getUser().hash)

        if(correct){
            if(passwordObj['passwordNew'] === passwordObj['passwordConf']){
                if(passwordObj['passwordNew'].length >= 7){

                    await changePassword()
                    if(passwordChanged){
                        alert("Your password has been successfully updated.")
                        passwordChanged = false;
                        navigate(`/app/cms`)
                    }else{
                        alert("the Operation failed. Please try again.")
                    }
                    
                }else{
                    alert("The new password should be at least 7 characters")
                }
               
            }else{
                alert("The fields are not the same.")
            }
        }else{
            alert("The password is incorrect.")   
        }
        //await event.preventDefault()
       // await handleLogin(this.state)
    }
    
    const handleUpdate = event => {
        passwordObj[event.target.name] = event.target.value
    }

  

    
    return (
    
    <Bground>
        <AdminNav/>
        <br></br>
        <Info>
            <form
                method="post"
                onSubmit={event => {
                (async () => { 
                    await handleSubmit(event)
                    //console.log("navigate to profile ", isLoggedIn())
                    //navigate(`/app/profile`)
                }) ();
                
                }}
            >
                <label>
                Old Password:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="password" name="passwordOld" onChange={handleUpdate}/>
                </label>
                <br></br>
                <br></br>
                <label>
                New Password:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="password" name="passwordNew" onChange={handleUpdate}/>
                </label>
                <br></br>
                <br></br>
                <label>
                Repeat New Password:&nbsp;
                <input
                    type="password"
                    name="passwordConf"
                    onChange={handleUpdate}
                />
                </label>
                <br></br>
                <br></br>
                <input type="submit" value="Confirm" style={{backgroundColor: '#FCD59D', color: 'white', padding: '5px', border: 'none', cursor: 'pointer', width: 'max-content', margin: 'auto', display: 'block'}}/>
            </form>
        </Info>
        <br></br>
    </Bground>
    
    )
  }


export default ChPassword;