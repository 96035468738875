import React, { PureComponent } from "react";
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'




const DropdownMenu = styled.div`
  overflow: hidden;
  background-color: #7d9be8;
  width: max-content;
  `
  const Menubtn = styled.button`
    font-size: 18px; 
    border: none;
    outline: none;
    color: white;
    padding: 5px 16px;
    background-color: inherit;
    font-family: inherit; 
    margin: 0; 
    width: 100%;
    cursor: pointer;
    
    :hover{
        background-color: #527ae0;
    }
  `
  const DdContent = styled.div`
    
    position: absolute;
    background-color: #A6BBEF;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    div {
        float: none;
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        cursor: pointer;
      }
    div:hover{
        background-color: #7d9be8;
    }
      ${p => p.open ? css`
        display: block;
      
]     
  ` : css`
        display: none; 
  `}

   `

 
class DropMenu extends PureComponent {
    state = {
        IsFolded: true,
    }
    
    toggleState = (evt) => {
        if(evt != undefined){
            evt.preventDefault()
        }
        this.setState(prevState => ({
            IsFolded: !prevState.IsFolded,
          }))
    }

    select = (evt) => {
        let category = ""
        evt.persist()
        evt.preventDefault()
        //console.log(evt._targetInst.pendingProps.name)
        category = evt._targetInst.pendingProps.name
        this.props.optionSelect(category)
        this.toggleState()
    }
    

   
    render() {
    //onBlur={()=>this.setState({IsFolded: true})}

      return (
        <DropdownMenu >
            <Menubtn onClick={this.toggleState} >
                {this.props.tittle}&nbsp;{this.props.selected} <FontAwesomeIcon icon={faCaretDown}/>
            </Menubtn>
            <DdContent open={!this.state.IsFolded}>
                {this.props.optionsArr.map((element,index) => (
                    <div key={index} name={element} onClick={this.select}>{element}</div>
                ))}
            </DdContent>
        </DropdownMenu>
      );
    }
  }
  
  export default DropMenu;