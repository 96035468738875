import styled from "@emotion/styled"

const Bground = styled.div`
    background-image: linear-gradient(#FDE3E3, #BDD3D5);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    min-height: 1080px;
    padding-bottom: 100px;
    position: relative;
    font-size: 16px;
    h2 {
        font-size: 20px;
    }
`

const AdminButton = styled.input`
    background-color: #FCD59D;
    color: white;
    padding: 5px;
    border: none;
    cursor: pointer;
    width: max-content;
    margin: auto;
    display: block;
    outline: 0;

    :hover{
        background-color: #fab452;
    }
`
const RemoveButton = styled.button`
    background-color: #ff9999;
    color: white;
    border: none;
    cursor: pointer;
    width: max-content;
    margin: auto;
    outline: 0;
    border-radius: 5px;
    :hover{
        background-color: #ff3333;
    }
`
const PublishButton = styled.button`
    background-color: #00e600;
    color: white;
    padding: 5px;
    border: none;
    cursor: pointer;
    width: max-content;
    margin: auto;
    display: block;
    outline: 0;

    :hover{
        background-color: #4dff4d;
    }
`

const SignWindow = styled.div`
    width: 90%;
    margin: auto;
    padding: 1rem;
    background-color: #afb9cf;
    color: white;
`
const TableContainer = styled.div`
    margin: 3rem auto;
    display: block;
    width: 80%;
    min-width: 340px;
    max-height: 600px;
    overflow: auto;
`
const EmailTable = styled.table
`
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
    width: 100%;

    align-items: center;
    border-collapse: collapse;
    overflow: auto;
    max-height: 300px;
    color: #7183AA;
    font-size: 1.6rem;

    td, th {
        border: 1px solid #7183AA;
        text-align: left;
        padding: 8px;
    }

    th  {
        background-color: #7183AA;
        color: #b3f1f3;
    }

    tr:nth-of-type(even)  {
        background-color: #7183AA;
        color: white;
    }

    tr:nth-of-type(odd)  {
        background-color: #8592ad;
        color: white;
    }
  `

const ComponentContainer = styled.div`

`

export{
    Bground,
    AdminButton,
    SignWindow,
    ComponentContainer,
    RemoveButton,
    PublishButton,
    TableContainer,
    EmailTable,
}