//const bcrypt = require('bcrypt');

 //var bcrypt = dcodeIO.bcrypt;
 var bcrypt = require('bcryptjs');

//const saltRounds = 10;

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

export const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = async ({ username, password }) => {
  //console.log("is the same pass? ",bcrypt.compareSync('$2a$10$xRjs/hBHhfVisrjTql4xuOqQCs5JK9WG2IFJm.ScOo775mK9VYH9e', '$2a$10$SrSWeNMYg4h1CYAEIT7z7e6mOIxtJw3fL.9dcmMvWkohJ8HJlNF.G'))

  if(username == '' || password == ''){
    return false
  }
  
  const salt = '$2a$10$xRjs/hBHhfVisrjTql4xuO'
  const hashedUser = bcrypt.hashSync(password, salt);
  //await fetch(`https://tradebot-beta.herokuapp.com/api/v2/beta/auth`,
  //await fetch(`http://ec2-3-135-182-236.us-east-2.compute.amazonaws.com:5000/api/v2/beta/auth`, 
  console.log("Hitting login async func")

  await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/login`,
    {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'email': username, 'password': hashedUser})})
      .then(res => res.json())
      .then(json => {
        console.log("this is my response: ",json)
        // console.log("this is my user object: ",getUser())
        if (json.response == true) {
          return setUser({
            email: username,
            hash: hashedUser,
          })
        }else{
          setUser({})
        }
      });
  return false
 
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.email
}

export const logout = callback => {
  // (async () => {  //funcion asincrona, es decir que retorna promesas en lugar de valores 
    
  //   //await fetch(`https://tradebot-beta.herokuapp.com/api/v2/beta/logout`, {method: "GET", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client" }) 
  //   await fetch(`http://ec2-3-135-182-236.us-east-2.compute.amazonaws.com:5000/api/v2/beta/logout`, {method: "GET", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client" }) 
  //   .then(res => res.json())
  //   .then(json =>  {
  //     console.log(json)
  //     });
  // }) ();
  
  setUser({})
  callback()
 
}