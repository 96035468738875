import React, {useState, useEffect} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import AdminNav from "../admin_components/nav-bar"
import { Bground, AdminButton, PublishButton } from "../admin_components/admin_common"
import NewsletterTittleEditable from "../admin_components/newsletter_components/title"
import NewsletterHighlightEditable from "../admin_components/newsletter_components/highlight"
import NewsletterPictureEditable from "../admin_components/newsletter_components/picture_frame"
import { getNewsletterId, setNewsletterId } from "./newsletter_cookie"
import DropMenu from '../admin_components/dropdown'
import { getUser } from "../services/auth"
import { navigate } from "gatsby";


const AddButton = AdminButton.withComponent('button')

const NewsletterBg = styled.div`
    background-color: white;
    width: 90%;
    margin: auto;
    padding: 2rem;
`
const LoadingBg = styled.div`
    background-color: rgba(20, 20, 20, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 0.3s;

    ${p => p.show ? css`
        visibility: visible;
        background-color: rgba(20, 20, 20, 0.6);
    ` : css`
        visibility: hidden;    
        background-color: rgba(20, 20, 20, 0);    
    `}

`


const today = new Date();//timezone adjustment
const anio = today.getFullYear();
const mes = today.getMonth() + 1;
const dia = today.getDate();


const newNewsletter = [
    {
        'name': 'title',
        'arg1': 'Newsletter Title',
        'arg2': mes+'/'+dia+'/'+anio,
    },
    {
        'name': 'highlight',
        'arg1': 'Highlight Title',
        'arg2': 'This is a paragraph',
    },

]



const componentSelectorState = {
    'title': function (arr) {
        arr.push(
            {
                'name': 'title',
                'arg1': 'Newsletter Title',
            }
        )
    },
    'highlight': function (arr) {
        arr.push(
            {
                'name': 'highlight',
                'arg1': 'Highlight Title',
                'arg2': 'This is a paragraph',
            }
        )
    },
    'picture': function (arr) {
        arr.push(
            {
                'name': 'picture',
                'arg1': 'This is the picture caption',//caption
                'arg2': 0,//db_id
                'arg3': 'panoramic',//img_setting: panoramic, middle, intext
            }
        )
    },
}

let img_arr = []
let thumbnail_arr = []
let loading = false;

const NewsletterEdit = () =>{

    const [Data, setData] = useState([]);
    const [Rendered, setRendered] = useState(false);
    // console.log('link args: ', getNewsletterId())

    let dropdown_options = ['highlight', 'picture']
   
    const addNLsection = (section) =>{
        var tempData = Data
        componentSelectorState[section](tempData)
        // console.log('next state: ', Data)
        
        setData(tempData)
        setRendered(!Rendered)
    }
    const getThumbnails = async () => {
        await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/get_thumbnails`,
        {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"email": getUser().email, "password": getUser().hash})})
        .then(res => res.json())
        .then(json => {
            thumbnail_arr = json
            // console.log('thumbnails: ', thumbnail_arr)    
        });
    }
    
    const getImages = async (content_arr) => {
        var repeated = 0
        // console.log('img_arr: ', img_arr)
        loading = true;
        setData(content_arr)

        for (let index = 0; index < content_arr.length; index++) {
            if(content_arr[index].name == 'picture' && content_arr[index].arg2 != 0){
                for(var i=0; i<img_arr.length; i++){
                    if(img_arr[i]['id'] == content_arr[index].arg2){
                        repeated++
                    }
                }
                if(repeated == 0){
                    await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/get_img`,
                    {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"email": getUser().email, "password": getUser().hash, 'img_id': content_arr[index].arg2})})
                    .then(res => res.json())
                    .then(json => {
                        // console.log('This is the server response: ', json[0])
                        // setData(JSON.parse(json[0].content))
                        img_arr.push({'id': content_arr[index].arg2, 'name': json.img_name, 'data': json.format +','+ json.encode})
                        // setRendered(!Rendered)        
                    });
                }else{
                    repeated = 0
                }
            }                
        }
        loading = false;     
        console.log('image loaded!')   
        setRendered(!Rendered)

    }

    useEffect(() => {
        // var data_temp

        const fetchData = async () => {
            if(getNewsletterId()['id'] == 'new'){
                console.log('yolo')
                await getThumbnails()
                setData(newNewsletter)
                
            }else{
                await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/newsletter`,
                {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'id': getNewsletterId()['id']})})
                .then(res => res.json())
                .then(json => {
                    // console.log('This is the server response: ', json[0])
                    // setData(JSON.parse(json[0].content))
                    // JSON.parse(json[0].content)
                    getThumbnails()
                    getImages(JSON.parse(json[0].content))
                    // setData(JSON.parse(json[0].content))
                });
            }
        }
        
        fetchData()  

    }, [])

    const removeComponent = (evt) =>{
        // console.log('this is the button I pressed: ', parseInt(evt.target.name))
        var tempData = Data
        tempData.splice(parseInt(evt.target.name), 1);
        setData(tempData)
        setRendered(!Rendered)
    }
    const textUpdate = (evt) =>{
        // console.log('this is the input I updated: ', evt.target.name[0])
        var tempData = Data
        
        if(evt.target.name[1] == 'a'){
            tempData[parseInt(evt.target.name[0])].arg1 = evt.target.value
        }else if (evt.target.name[1] == 'b'){
            tempData[parseInt(evt.target.name[0])].arg2 = evt.target.value
        }  
        setData(tempData)
        setRendered(!Rendered)

    }

    const imgUpdate = async(obj) =>{
        var tempData = Data

        tempData[parseInt(obj.index)].arg1 = obj.arg1
        tempData[parseInt(obj.index)].arg2 = obj.arg2
        tempData[parseInt(obj.index)].arg3 = obj.arg3
        
        getThumbnails()
        await getImages(tempData)
        // setData(tempData)
        // setRendered(!Rendered)
    }


    const componentSelectorDisplay = {
        'title': function (arg1, arg2, index) {
            return(
                <NewsletterTittleEditable arg1={arg1} arg2={arg2} index={index} removeFn={removeComponent} textChange={textUpdate}/>
            )
        },
        'highlight': function (arg1, arg2, index) {
            return(
                    <NewsletterHighlightEditable arg1={arg1} arg2={arg2} index={index} removeFn={removeComponent} textChange={textUpdate}/>
            )
        },
        'picture': function (arg1, arg2, index, arg3) {
            var img
            for(var i=0; i<img_arr.length; i++){
                if(img_arr[i]['id'] == arg2){
                    img = img_arr[i]['data']
                }
            }
            return(
                    <NewsletterPictureEditable thumbnails={thumbnail_arr} arg1={arg1} arg2={arg2} arg3={arg3} srcImg={img} index={index} removeFn={removeComponent} imgUpdate={imgUpdate}/>
            )
        },
    }

    const saveNL = async(evt) =>{
        // (async () => {
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/save_newsletter`,
                {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'id': getNewsletterId()['id'], "email": getUser().email, "password": getUser().hash, "content": JSON.stringify(Data)})})
                .then(res => res.json())
                .then(json => {
                    alert('Newsletter succesfully saved')
                    setNewsletterId({'id': json.id})
                });
        
        // })
    }

    const backSaveNL = async(evt) =>{
            // (async () => {
                await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/save_newsletter`,
                {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'id': getNewsletterId()['id'], "email": getUser().email, "password": getUser().hash, "content": JSON.stringify(Data)})})
                .then(res => res.json())
                .then(json => {
                    alert('Newsletter succesfully saved')
                    // setNewsletterId({'id': json.id})
                    navigate('/app/newsletter/')
                });
        
        // })
    }

    return(
        <Bground style={{color:'black'}}>
            <LoadingBg show={loading}/>
            <AdminNav/>
            <br></br>
            <NewsletterBg>
                {Data.map((element, index) => (
                    <div key={index}>
                    {componentSelectorDisplay[element.name](element.arg1, element.arg2, index, element.arg3)}
                    </div>
                ))}
            </NewsletterBg>
            <br></br>
            <div style={{margin:'auto', width:'max-content'}}>
                <PublishButton  onClick={backSaveNL} style={{float:'left',  marginRight:'1rem'}}>Go back and Save</PublishButton>
                <AddButton onClick={saveNL} style={{float:'left',  marginRight:'1rem'}}>Save</AddButton>
                <DropMenu style={{float:'left'}} tittle="" optionsArr={dropdown_options} optionSelect={addNLsection} selected={'Add new section'}/>
            </div>
            
        </Bground>
    )

}

export default NewsletterEdit