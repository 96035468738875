//import React, { Component } from "react"
import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/app/admin`) {
    if(typeof window !== "undefined"){
      navigate("/app/admin")
    }
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute