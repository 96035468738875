import React, {useState, useEffect} from "react"
import { navigate } from "gatsby"
// import styled from "@emotion/styled"
import AdminNav from "../admin_components/nav-bar"
import { Bground, AdminButton, PublishButton, RemoveButton } from "../admin_components/admin_common"
import { setNewsletterId } from "./newsletter_cookie"
import { getUser } from "../services/auth"



const AddButton = AdminButton.withComponent('button')


const newsletter_obj = {'id':'new'}

const goToEdit = (evt) =>{
    var newsletter_obj_temp = {'id': evt.target.name}
    setNewsletterId(newsletter_obj_temp)
    // console.log('NL id: ', evt.target.name)
    // console.log('NL id: ', getNewsletterId()['id'])
    navigate('/app/newsletter_edit')
}

const createNL = () =>{
    setNewsletterId(newsletter_obj)
    // console.log('NL id: ', getNewsletterId()['id'])
    navigate('/app/newsletter_edit')
}

const publishNL = async(evt) =>{
    // setNewsletterId(newsletter_obj)
    // console.log('NL id: ', getNewsletterId()['id'])
    // navigate('/app/newsletter_edit')
    await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/publish_newsletter`,
    {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'id': evt.target.name, "email": getUser().email, "password": getUser().hash})})
    .then(res => res.json())
    .then(json => {
        console.log('message: ', json)
        // alert('Newsletter succesfully published')
        // // navigate('/app/newsletter')
        // window.location.reload();
        // setNewsletterId({'id': json.id})
    });

    //access the netlify build hook to trigger a hook
    await fetch(`https://api.netlify.com/build_hooks/5fbc64ec547535160d45b97c`,
    {method: "POST", mode: "no-cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body:{}})
  
    alert('Newsletter succesfully published')
    window.location.reload();
}

const publisEdit = async(evt) =>{
    //access the netlify build hook to trigger a hook
    await fetch(`https://api.netlify.com/build_hooks/5fbc64ec547535160d45b97c`,
    {method: "POST", mode: "no-cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body:{}})
  
    alert('Page succesfully published')
    window.location.reload();
}

const removeFn = async(evt) =>{
    // console.log('this is the button I pressed: ', parseInt(evt.target.name))
    // var tempData = Data
    // tempData.splice(parseInt(evt.target.name), 1);
    // setData(tempData)
    // setRendered(!Rendered)
    await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/delete_newsletter`,
    {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'id': evt.target.name, "email": getUser().email, "password": getUser().hash})})
    .then(res => res.json())
    .then(json => {
        console.log('message: ', json.message)
        alert(json.message)
        // // navigate('/app/newsletter')
        window.location.reload();
        // setNewsletterId({'id': json.id})
    });
}

const NewsletterCMS = () =>{
    const [Data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/newsletter`,
            {method: "GET", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client"})
            .then(res => res.json())
            .then(json => {
                // console.log(json)
                // yolovar = JSON.parse(json[0].content)[0].arg1
                // console.log('yolo2: ', yolovar)
                setData(json)
            });
        }
        fetchData()
    }, [])

    return(
        <Bground style={{color:'black'}}>
            <AdminNav/>
            <br></br>
            <br></br>
            <div style={{margin:'auto', width:'max-content'}}>
                <AddButton  style={{display:'inline'}} onClick={createNL}>Click to create a new Newsletter</AddButton>
                <PublishButton style={{display:'inline'}} onClick={publisEdit} style={{float:'left',  marginRight:'1rem'}}>Publish Page</PublishButton>
            </div>
            <br></br>
            <br></br>
            <h2 style={{textAlign:'center'}}>There are {Data.length} results in total</h2>
            {Data.map((element, index) => (
                <div key={index}>
                <div style={{backgroundColor:'white', padding: '1rem', width:'90%', margin:'auto'}} key={index}>
                    <h2 style={{marginBottom:'0'}}>{JSON.parse(element.content)[0].arg1}</h2>
                    <span>{JSON.parse(element.content)[0].arg2}</span>
                    <br></br>
                    <span>Published: {element.published}</span>
                    <br></br>
                    {element.published === 'NO' ? (
                      <div style={{width:'max-content', overflow:'auto'}}>
                      <AddButton name={element.id} onClick={goToEdit} style={{float:'left',  marginRight:'1rem'}}>Edit</AddButton>
                      <PublishButton name={element.id} onClick={publishNL} style={{float:'left',  marginRight:'1rem'}}>Publish</PublishButton>
                      <RemoveButton  name={element.id} onClick={removeFn} style={{borderRadius:'0', padding:'0.5rem', display:'inline-block'}}>Delete</RemoveButton>
                      </div>
                    ) : <>
                      <div style={{width:'max-content', overflow:'auto'}}>
                        <AddButton name={element.id} onClick={goToEdit} style={{float:'left',  marginRight:'1rem'}}>Edit</AddButton>
                        <RemoveButton  name={element.id} onClick={removeFn} style={{borderRadius:'0', padding:'0.5rem', display:'inline-block'}}>Delete</RemoveButton>
                      </div>
                        </>}
                </div>
                <br></br>
                </div>

            ))}
        </Bground>
    )

}

export default NewsletterCMS