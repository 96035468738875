import React from "react"
// import { navigate } from "gatsby"
import { handleLogin, isLoggedIn, getUser} from "../services/auth"
import styled from "@emotion/styled"
import AdminNav from "../admin_components/nav-bar"
import { Bground, AdminButton } from "../admin_components/admin_common"
import { navigate } from "gatsby";

const AddButton = AdminButton.withComponent('button')


const SignWindow = styled.div`
    width: 90%;
    margin: auto;
    padding: 1rem;
    background-color: #afb9cf;
    color: white;

    font-size: 16px;
    h1 {
        font-size: 24px;
    }
`


const CMS = () =>{

    return(
        <Bground>
            <AdminNav/>
            <br></br>
            <SignWindow>
                <h1>Welcome back {getUser().email}!</h1>
                <p>Welcome to the administration panel. From this panel you can add and modify some of the content of the website. You can also access some data related the website like email lists.</p>
            </SignWindow>
            <br></br>
            <SignWindow>
                <h1>Change password for {getUser().email}</h1>
                <AddButton onClick={()=>navigate('/app/ch_password')}>Change</AddButton>
            </SignWindow>

        </Bground>
    )

}

export default CMS